.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body {
  background: #ffffff url("../images/top_bg.jpg") no-repeat center top;
  line-height: 1.2;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  color: #000000;
}

.p-header {
  display: block;
  position: relative;
  padding: 25px 0;
  & > .container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
  }
  &__logo {
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    margin: -5px 0 0 0;
    img,
    svg {
      display: inline-block;
      max-width: 260px;
      max-height: 45px;
    }
    &-text {
      display: inline-block;
      margin: 0 0 0 40px;
      color: #191919;
      font-size: 14px;
      font-weight: 500;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }
  &__city {
    display: inline-block;
    margin: 0;
    color: #191919;
    font-size: 14px;
    font-weight: 500;
  }
  &__link {
    display: inline-block;
    border-bottom: 1px dashed #e0202b;
    color: #e0202b;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      border-color: transparent;
      opacity: 0.8;
      color: #e0202b;
      text-decoration: none;
    }
  }
  &__right {
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-end;
  }
  &__phone {
    display: block;
    margin: 0 0 6px 0;
    color: #191919;
    font-size: 24px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      color: #e0202b;
      text-decoration: none;
    }
  }
  &__text {
    display: block;
    margin: 18px 0 0 0;
    color: #afafaf;
    font-size: 14px;
    font-weight: 500;
  }
  &__button {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 8px 25px;
    border-radius: 12px;
    background-image: linear-gradient(to top, #e0202b 0%, #f2414c 100%);
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    transform-style: preserve-3d;
    & > * {
      position: relative;
      z-index: 2;
    }
    img,
    svg {
      display: none;
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 4px;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateZ(-1px);
      box-shadow: 1px 1px 16px rgba(224, 32, 43, 0.42);
      border-radius: 12px;
      background-color: #970c14;
      pointer-events: none;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      &:after {
        background-color: #ca373f;
      }
      background-image: linear-gradient(to top, #ff676f 0%, #ff646e 100%);
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.p-top {
  display: block;
  width: 100%;
  overflow: hidden;
  padding: 70px 0;
  &__title {
    display: block;
    margin: 0 0 32px 0;
    color: #191919;
    font-size: 50px;
    font-weight: 900;
    line-height: 1.44;
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
    }
  }
  &__text {
    display: block;
    margin: 0 0 38px 0;
    color: #191919;
    font-size: 26px;
    font-weight: 700;
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
    }
  }
  &__subtext {
    display: block;
    margin: 0 0 42px 0;
    color: #191919;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
    b,
    strong {
      font-weight: 700;
    }
  }
  &__button {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 18px 44px;
    border-radius: 12px;
    background-image: linear-gradient(to top, #e0202b 0%, #f2414c 100%);
    color: #ffffff;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    transform-style: preserve-3d;
    & > * {
      position: relative;
      z-index: 2;
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 4px;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateZ(-1px);
      box-shadow: 1px 1px 16px rgba(224, 32, 43, 0.42);
      border-radius: 12px;
      background-color: #970c14;
      pointer-events: none;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      &:after {
        background-color: #ca373f;
      }
      background-image: linear-gradient(to top, #ff676f 0%, #ff646e 100%);
      color: #ffffff;
      text-decoration: none;
    }
  }
  &__info {
    display: block;
    position: relative;
    margin: 30px 0 0 0;
    color: #191919;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    &:before {
      display: block;
      position: absolute;
      content: "";
      left: -45px;
      top: -65px;
      width: 31px;
      height: 82px;
      background: url("../images/top_arrow.png") no-repeat center center;
      background-size: contain;
      pointer-events: none;
    }
  }
}

.p-features {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 100px 0 65px 0;
  &-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 370px;
    margin: 0 auto 30px auto;
    text-align: center;
    &__image {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      height: 225px;
      img,
      svg {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }
    &__title {
      display: block;
      margin: 25px 0 20px 0;
      color: #191919;
      font-size: 24px;
      font-weight: 900;
      b,
      strong {
        color: #e0202b;
        font-weight: 900;
      }
    }
    &__text {
      display: block;
      margin: 0;
      color: #191919;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      b,
      strong {
        color: #999999;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.p-how {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 70px 0 0 0;
  background: url("../images/how_bg.png") no-repeat right 50vw top;
  &__title {
    display: block;
    margin: 0 0 35px 0;
    color: #191919;
    font-size: 40px;
    font-weight: 900;
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
    }
  }
  &__text {
    display: block;
    margin: 0 0 30px 0;
    color: #191919;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
  }
  &__subtext {
    display: block;
    margin: 0 0 70px 0;
    color: #191919;
    font-size: 40px;
    font-weight: 700;
    b,
    strong {
      color: #e0202b;
      font-weight: 700;
    }
  }
  &__centered {
    display: block;
    position: relative;
    max-width: 450px;
    margin: 0 auto;
    padding: 12px 40px;
    border-radius: 12px 12px 0 0;
    background-color: #4f5f2a;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
}

.p-stroke {
  display: block;
  position: relative;
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  box-shadow: 0 7px 43px rgba(0, 0, 0, 0.3);
  background-color: #e0202b;
  color: #ffffff;
  white-space: nowrap;
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 100px;
  & > span {
    position: absolute;
    transform: translateX(100%);
    animation: scroll-left 200s linear infinite;
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.p-real {
  display: block;
  width: 100%;
  overflow: hidden;
  padding: 80px 0 160px 0;
  background: url("../images/real_bg.jpg") no-repeat center center;
  background-size: cover;
  &__good {
    display: block;
    position: absolute;
    top: 120px;
    left: -60px;
    pointer-events: none;
    z-index: 2;
  }
  &__bad {
    display: block;
    position: absolute;
    top: 70px;
    right: -70px;
    pointer-events: none;
    z-index: 2;
  }
  &__title {
    display: block;
    margin: 0 0 36px 0;
    color: #ffffff;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
  }
  &__left {
    display: block;
    position: relative;
    max-width: 250px;
    margin: 55px auto 0 auto;
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    &:before {
      display: block;
      position: absolute;
      content: "";
      bottom: 16px;
      right: 100%;
      width: 73px;
      height: 105px;
      background: url("../images/arrow_green.png") no-repeat center center;
      background-size: contain;
      pointer-events: none;
    }
  }
  &__right {
    display: block;
    position: relative;
    max-width: 245px;
    margin: 25px auto 0 auto;
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    &:before {
      display: block;
      position: absolute;
      content: "";
      bottom: 20px;
      left: 100%;
      width: 73px;
      height: 105px;
      background: url("../images/arrow_red.png") no-repeat center center;
      background-size: contain;
      pointer-events: none;
    }
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 470px;
    min-height: 440px;
    margin: 0 auto 30px auto;
    padding: 46px 50px 20px 50px;
    box-shadow: 0 7px 30px rgba(0, 0, 0, 0.7);
    border-radius: 30px;
    background-color: #ffffff;
    &__title {
      display: block;
      margin: 0 0 45px 0;
      color: #3eba2e;
      font-size: 30px;
      font-weight: 900;
      &_bad {
        color: #e0202b;
      }
    }
    &__ul {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: block;
        position: relative;
        margin: 0 0 28px 0;
        padding: 0 0 0 38px;
        background: url("../images/icon_ok.png") no-repeat 0 0;
        color: #191919;
        font-size: 20px;
        font-weight: 500;
      }
      &_bad {
        li {
          background: url("../images/icon_bad.png") no-repeat 0 0 !important;
        }
      }
    }
  }
}

.p-yes {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: -250px 0 0 0;
  padding: 300px 0 20px 0;
  background: url("../images/yes_bg.png") no-repeat center top;
  background-size: 100% auto;
  pointer-events: none;
  z-index: 1;
  &__mob {
    display: none;
  }
  &__title {
    display: block;
    margin: 0 0 42px 0;
    color: #191919;
    font-size: 40px;
    font-weight: 900;
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
    }
  }
  &__text {
    display: block;
    margin: 0 0 36px 0;
    color: #191919;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    b,
    strong {
      font-weight: 700;
    }
  }
  &__button {
    display: inline-block;
    position: relative;
    margin: 20px 0 0 0;
    padding: 17px 40px;
    border-radius: 12px;
    background-image: linear-gradient(to top, #e0202b 0%, #f2414c 100%);
    color: #ffffff;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    transform-style: preserve-3d;
    pointer-events: all;
    & > * {
      position: relative;
      z-index: 2;
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 4px;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateZ(-1px);
      box-shadow: 1px 1px 16px rgba(224, 32, 43, 0.42);
      border-radius: 12px;
      background-color: #970c14;
      pointer-events: none;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      &:after {
        background-color: #ca373f;
      }
      background-image: linear-gradient(to top, #ff676f 0%, #ff646e 100%);
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.p-law {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: url("../images/law_bg.png") no-repeat center center;
  background-size: 100% auto;
  &__title {
    display: block;
    margin: 0 0 44px 0;
    color: #191919;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
    }
  }
  &__text {
    display: block;
    margin: 0 0 46px 0;
    color: #191919;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    b,
    strong {
      font-size: 30px;
      font-weight: 700;
    }
  }
  &__image {
    display: block;
    position: absolute;
    margin: -50px 0 0 -80px;
    pointer-events: none;
    z-index: 1;
  }
  &-card {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 100%;
    margin: 0 0 26px 0;
    &__number {
      display: inline-block;
      flex: 0 0 64px;
      margin: -6px 0 0 0;
      text-shadow: 0 0 16px rgba(224, 32, 43, 0.45);
      color: #e0202b;
      font-family: "Montserrat", sans-serif;
      font-size: 50px;
      font-weight: 700;
    }
    &__title {
      display: block;
      margin: 0 0 4px 0;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      font-weight: 700;
    }
    &__text {
      display: block;
      margin: 0;
      color: #191919;
      font-size: 16px;
      line-height: 1.5;
    }
  }
  &-block {
    display: block;
    position: relative;
    margin: 80px 0 0 0;
    padding: 40px 300px 40px 55px;
    box-shadow: 0 25px 87px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    background: #901e1a url("../images/law_bg_2.png") no-repeat right top;
    &__title {
      display: block;
      margin: 0 0 30px 0;
      color: #ffffff;
      font-size: 40px;
      font-weight: 900;
    }
    &__text {
      display: block;
      margin: 0;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.88;
    }
  }
  &__button {
    display: inline-block;
    position: relative;
    margin: 20px 0 0 0;
    padding: 17px 58px;
    border-radius: 12px;
    background-image: linear-gradient(to top, #e0202b 0%, #f2414c 100%);
    color: #ffffff;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    transform-style: preserve-3d;
    pointer-events: all;
    & > * {
      position: relative;
      z-index: 2;
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 4px;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateZ(-1px);
      box-shadow: 1px 1px 16px rgba(224, 32, 43, 0.42);
      border-radius: 12px;
      background-color: #970c14;
      pointer-events: none;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      &:after {
        background-color: #ca373f;
      }
      background-image: linear-gradient(to top, #ff676f 0%, #ff646e 100%);
      color: #ffffff;
      text-decoration: none;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0) translateZ(-1px);
  }
  100% {
    transform: rotate(360deg) translateZ(-1px);
  }
}

@keyframes respin {
  0% {
    transform: rotate(0) translateZ(-2px);
  }
  100% {
    transform: rotate(-360deg) translateZ(-2px);
  }
}

.p-steps {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 90px 0 110px 0;
  /* background: url("../images/step_bg.png") no-repeat left calc(50vw - 110px) bottom 110px; */
  &__bilet {
    display: block;
    position: absolute;
    bottom: 100px;
    right: 130px;
    width: 413px;
    height: 540px;
    background: url("../images/bilet.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transform-style: preserve-3d;
    z-index: 0;
    &:before {
      display: block;
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      width: 574px;
      height: 574px;
      margin-top: -302px;
      margin-left: -287px;
      background: url("../images/bilet_before.png") no-repeat center center;
      background-size: contain;
      transform: translateZ(-1px);
      animation: respin 30s linear infinite;
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      width: 744px;
      height: 744px;
      margin-top: -397px;
      margin-left: -372px;
      background: url("../images/bilet_after.png") no-repeat center center;
      background-size: contain;
      transform: translateZ(-2px);
      animation: spin 30s linear infinite;
    }
  }
  &__title {
    display: block;
    margin: 0 0 44px 0;
    color: #191919;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
    }
  }
  &__text {
    display: block;
    margin: 0 0 30px 0;
    color: #191919;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    b,
    strong {
      color: #e0202b;
      font-weight: 700;
    }
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 370px;
    margin: 60px auto 0 auto;
    text-align: center;
    z-index: 3;
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 125px;
      right: -50px;
      width: 75px;
      height: 20px;
      background: url("../images/arrow_right.png") no-repeat center center;
      background-size: contain;
    }
    &_last {
      margin: 10px auto 85px auto;
    }
    &_no-arrow {
      &:after {
        display: none !important;
      }
    }
    &__image {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 220px;
      img,
      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
    &__title {
      display: block;
      margin: 38px 0 0 0;
      color: #191919;
      font-size: 19px;
      font-weight: 700;
      line-height: 1.5;
      b,
      strong {
        color: #e0202b;
        font-weight: 700;
      }
    }
    &__block {
      display: block;
      width: 100%;
      margin: 22px 0 0 0;
      padding: 18px;
      box-shadow: 0 3px 16px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      background-color: #ffffff;
    }
    &__subtext {
      display: block;
      margin: 0;
      color: #191919;
      line-height: 1.5;
      font-size: 16px;
      font-weight: 500;
      b,
      strong {
        color: #e0202b;
        font-weight: 700;
      }
    }
  }
  blockquote {
    display: block;
    position: relative;
    width: 100%;
    max-width: 630px;
    margin: 0;
    padding: 44px 50px 44px 60px;
    border-radius: 20px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    background: url("../images/steps_bg_2.jpg") no-repeat center center;
    background-size: cover;
    z-index: 3;
    &:before {
      display: block;
      position: absolute;
      content: "";
      top: 34px;
      left: 30px;
      width: 4px;
      height: calc(100% - 68px);
      box-shadow: 0 0 6px rgba(224, 32, 43, 0.42);
      border-radius: 2px;
      background-color: #e0202b;
    }
  }
}

.p-working {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 70px 0 55px 0;
  background: url("../images/working_bg.png") no-repeat right 50vw center;
  &__title {
    display: block;
    margin: 0 0 40px 0;
    color: #191919;
    font-size: 40px;
    font-weight: 900;
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
    }
  }
  &__text {
    display: block;
    margin: 0 0 54px 0;
    color: #191919;
    font-size: 29px;
    font-weight: 500;
  }
  &__button {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 18px 48px;
    border-radius: 12px;
    background-image: linear-gradient(to top, #e0202b 0%, #f2414c 100%);
    color: #ffffff;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    transform-style: preserve-3d;
    pointer-events: all;
    & > * {
      position: relative;
      z-index: 2;
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 4px;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateZ(-1px);
      box-shadow: 1px 1px 16px rgba(224, 32, 43, 0.42);
      border-radius: 12px;
      background-color: #970c14;
      pointer-events: none;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      &:after {
        background-color: #ca373f;
      }
      background-image: linear-gradient(to top, #ff676f 0%, #ff646e 100%);
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.p-formula {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 95px 0 55px 0;
  background: url("../images/formula_bg.jpg") no-repeat left 50vw top;
  &__title {
    display: block;
    margin: 0 0 34px 0;
    color: #191919;
    font-size: 40px;
    font-weight: 400;
    font-weight: 900;
    z-index: 3;
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
    }
  }
  &__text {
    display: block;
    margin: 0 0 46px 0;
    color: #191919;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    z-index: 3;
    b,
    strong {
      font-weight: 700;
    }
  }
  &__block {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 900px;
    margin: 0;
    padding: 16px 12px;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background-color: #ffffff;
    color: #191919;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    text-transform: uppercase;
    text-align: center;
    z-index: 3;
    &_red {
      color: #e0202b;
      font-size: 50px;
      font-weight: 500;
    }
    b,
    strong {
      font-weight: 900;
    }
  }
}

.p-way {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: -190px;
  padding: 250px 0 30px 0;
  background: url("../images/way_bg.png") no-repeat center top;
  background-size: 100% auto;
  &__1 {
    display: block;
    position: absolute;
    top: 200px;
    left: -140px;
    pointer-events: none;
    z-index: 2;
  }
  &__2 {
    display: block;
    position: absolute;
    bottom: 60px;
    left: -120px;
    pointer-events: none;
    z-index: 2;
  }
  &__3 {
    display: block;
    position: absolute;
    top: 90px;
    right: -90px;
    pointer-events: none;
    z-index: 2;
  }
  &__4 {
    display: block;
    position: absolute;
    bottom: 50px;
    right: -180px;
    pointer-events: none;
    z-index: 2;
  }
  &__title {
    display: block;
    margin: 0 0 65px 0;
    color: #591208;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    span {
      display: none;
    }
    &_alt {
      color: #134a7b;
    }
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 570px;
    min-height: 750px;
    margin: 0 auto 30px auto;
    padding: 50px 50px 20px 50px;
    box-shadow: 0 7px 43px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    background-color: #ffffff;
    &__title {
      display: block;
      margin: 0 0 46px 0;
      color: #3eba2e;
      font-size: 30px;
      font-weight: 900;
      &_bad {
        color: #e0202b;
      }
    }
    &__ul {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: block;
        position: relative;
        margin: 0 0 30px 0;
        padding: 0 0 0 28px;
        background: url("../images/way_ul_ok.png") no-repeat 10px 6px;
        color: #191919;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
      }
      &_bad {
        li {
          background: url("../images/way_ul_bad.png") no-repeat 10px 6px !important;
        }
      }
    }
  }
}

.p-prices {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 50px 0 60px 0;
  &__title {
    display: block;
    position: relative;
    margin: 0 0 36px 0;
    color: #191919;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
    }
  }
  .swiper-pagination {
    display: none;
  }
  &-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
    max-width: 370px;
    overflow: hidden;
    margin: 0 auto 30px auto;
    padding: 28px 30px 30px 30px;
    box-shadow: 0 8px 27px rgba(0, 0, 0, 0.33);
    border-radius: 10px;
    background-color: #ffffff;
    &__title {
      display: block;
      margin: 0;
      color: #e0202b;
      font-size: 20px;
      font-weight: 900;
      transition: all 0.2s ease-out;
    }
    &__subtitle {
      display: block;
      margin: 0 0 2px 0;
      color: #191919;
      font-size: 16px;
      font-weight: 500;
      transition: all 0.2s ease-out;
    }
    &__hr {
      display: block;
      width: 100%;
      height: 1px;
      margin: 14px 0 18px 0;
      background-color: #d3d3d3;
      transition: all 0.2s ease-out;
    }
    &__row {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 100%;
      padding: 0;
      border-top: 1px solid #d3d3d3;
      transition: all 0.2s ease-out;
    }
    &__price {
      display: inline-block;
      margin: 16px 30px 16px 0;
      color: #191919;
      font-size: 14px;
      font-weight: 500;
      font-family: "Montserrat", sans-serif;
      transition: all 0.2s ease-out;
      b,
      strong {
        font-size: 20px;
        font-weight: 700;
      }
    }
    &__button {
      display: inline-block;
      position: relative;
      flex: 1 1 auto;
      margin: 0;
      padding: 9px 10px;
      border-radius: 10px;
      background-color: #e0202b;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      & > * {
        position: relative;
        z-index: 2;
      }
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        background-image: linear-gradient(to top, #ff676f 0%, #ff646e 100%);
        color: #ffffff;
        text-decoration: none;
      }
    }
    ul {
      display: block;
      position: relative;
      flex: 1 1 auto;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: block;
        position: relative;
        margin: 0 0 20px 0;
        color: #191919;
        font-size: 16px;
        font-weight: 400;
        transition: all 0.2s ease-out;
        &:before {
          display: block;
          position: absolute;
          content: "";
          top: 8px;
          left: -30px;
          width: 15px;
          height: 2px;
          background-color: #e0202b;
          transition: all 0.2s ease-out;
        }
      }
      transition: all 0.2s ease-out;
    }
    text-decoration: none;
    transition: all 0.2s ease-out;
    &_colored {
      box-shadow: 0 8px 27px rgba(0, 0, 0, 0.33);
      background-color: #ce2c36;
      text-decoration: none;
      .p-prices-card__subtitle {
        color: #ffffff;
      }
      .p-prices-card__title {
        color: #ffffff;
      }
      .p-prices-card__hr {
        background-color: #f85b64;
      }
      .p-prices-card__price {
        color: #ffffff;
      }
      .p-prices-card__button {
        background: #ffffff;
        color: #191919;
      }
      .p-prices-card__row {
        border-color: #f85b64;
      }
      ul {
        color: #ffffff;
        li {
          color: #ffffff;
          &:before {
            background-color: #ffffff;
          }
        }
      }
    }
  }
}

.p-credit {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0 0 90px 0;
  padding: 65px 0 55px 0;
  border-radius: 30px;
  background: #454f2c url("../images/credit_bg.jpg") no-repeat center center;
  background-size: cover;
  &__title {
    display: block;
    margin: 0 0 20px 0;
    text-shadow: 0 7px 18px rgba(0, 0, 0, 0.95);
    color: #ffffff;
    font-size: 30px;
    font-weight: 900;
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
      font-size: 40px;
    }
  }
  &__text {
    display: block;
    margin: 0 0 40px 0;
    color: #ffffff;
    font-size: 19px;
    font-weight: 500;
    line-height: 1.5;
  }
  &__button {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 18px 28px;
    border-radius: 12px;
    background-image: linear-gradient(to top, #e0202b 0%, #f2414c 100%);
    color: #ffffff;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    transform-style: preserve-3d;
    pointer-events: all;
    & > * {
      position: relative;
      z-index: 2;
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 4px;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateZ(-1px);
      box-shadow: 1px 1px 16px rgba(224, 32, 43, 0.42);
      border-radius: 12px;
      background-color: #970c14;
      pointer-events: none;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      &:after {
        background-color: #ca373f;
      }
      background-image: linear-gradient(to top, #ff676f 0%, #ff646e 100%);
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.p-reviews {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 0 100px 0;
  &__title {
    display: block;
    position: relative;
    margin: 0 0 120px 0;
    color: #191919;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
    }
  }
  &__cards {
    display: block;
    position: relative;
    width: 100%;
    max-width: 400px;
    overflow: visible;
    margin: 0 auto;
    transform-style: preserve-3d;
    &:before {
      display: block;
      position: absolute;
      content: "";
      left: 50%;
      top: 50%;
      width: 374px;
      height: 746px;
      margin-left: -187px;
      margin-top: -373px;
      border-radius: 60px;
      background: url("../images/reviews_bg.png") no-repeat center center;
      background-size: contain;
      pointer-events: none;
      transform-style: preserve-3d;
      z-index: 3;
    }
    .swiper-wrapper {
      display: flex !important;
      align-items: center;
    }
    .swiper-slide-active {
      .p-reviews-card {
        max-width: 330px;
        min-height: 615px;
        background: transparent;
        box-shadow: none;
        &__logo {
          display: none;
        }
        &__info {
          display: block;
          margin-bottom: 30px;
        }
      }
    }
  }
  &-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
    box-shadow: 0 9px 40px rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    & > img,
    & > svg {
      display: block;
      max-width: 100%;
      height: 455px;
    }
    &__info {
      display: none;
      position: relative;
      width: 100%;
      background-color: #970c14;
      text-align: center;
      transform: translateZ(2px);
      z-index: 6;
    }
    &__who {
      display: block;
      margin: 0 0 2px 0;
      color: #ffffff;
      font-size: 20px;
      font-weight: 900;
    }
    &__date {
      display: block;
      margin: 0 0 6px 0;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
    }
    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 75px;
      height: 75px;
      margin-top: -30px;
      padding: 10px;
      background: #ffffff;
      border-radius: 999rem;
      img,
      svg {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      box-shadow: none;
      text-decoration: none;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    &:before,
    &:after {
      display: none;
    }
    width: 50px;
    height: 24px;
    top: 620px;
    background-size: contain;
    outline: none;
    opacity: 1;
    filter: brightness(0);
    z-index: 10;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      filter: none;
      text-decoration: none;
    }
  }
  .swiper-button-prev {
    left: 120px;
    background: url("../images/reviews_prev.png") no-repeat center center;
  }
  .swiper-button-next {
    right: 120px;
    background: url("../images/reviews_next.png") no-repeat center center;
  }
}

.p-done {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 50px 0;
  &__info {
    display: block;
    position: relative;
    margin: 45px 0 0 0;
    color: #191919;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
  }
  &__title {
    display: block;
    position: relative;
    margin: 0 0 30px 0;
    color: #191919;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
    }
  }
  &__cards {
    display: block;
    position: relative;
    width: 100%;
    overflow: visible;
    .swiper-wrapper {
      transition-timing-function: linear !important;
    }
    .swiper-slide {
      display: block;
      width: 100%;
      max-width: 220px;
      height: 300px;
      border-radius: 10px;
      background-position: center center;
      background-size: cover;
    }
  }
}

.p-team {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 80px 0 50px 0;
  &__title {
    display: block;
    position: relative;
    margin: 0 0 50px 0;
    color: #191919;
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
    }
    &_big {
      font-size: 40px;
    }
  }
  &__cards {
    display: block;
    position: relative;
    width: 100%;
    max-width: 670px;
    overflow: visible;
    margin: 0 auto;
    .swiper-button-next,
    .swiper-button-prev {
      &:before,
      &:after {
        display: none;
      }
      width: 52px;
      height: 50px;
      outline: none;
      opacity: 1;
      background-size: contain;
      z-index: 10;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
    .swiper-button-prev {
      left: 0;
      margin-left: -25px;
      background: url("../images/team_prev.png") no-repeat center center;
    }
    .swiper-button-next {
      right: 0;
      margin-right: -25px;
      background: url("../images/team_next.png") no-repeat center center;
    }
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 670px;
    min-height: 385px;
    margin: 0 auto;
    padding: 44px 50px;
    box-shadow: 5px 6px 70px rgba(0, 0, 0, 0.14);
    border-radius: 20px;
    background-color: #ffffff;
    &__name {
      display: block;
      margin: 0 0 15px 0;
      color: #191919;
      font-size: 24px;
      font-weight: 900;
    }
    &__who {
      display: block;
      margin: 0 0 32px 0;
      color: #191919;
      font-size: 16px;
      font-weight: 500;
    }
    &__subtitle {
      display: block;
      margin: 0 0 2px 0;
      color: #a7a7a7;
      font-size: 14px;
      font-weight: 500;
    }
    &__text {
      display: block;
      margin: 0 0 15px 0;
      color: #191919;
      font-size: 16px;
      font-weight: 400;
    }
    &__image {
      display: flex;
      position: relative;
      width: 100%;
      height: 180px;
      img,
      svg {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 260px;
        max-height: 260px;
      }
    }
    blockquote {
      display: block;
      position: relative;
      margin: 20px 0 10px 0;
      padding: 6px 50px 6px 30px;
      color: #191919;
      font-size: 16px;
      font-weight: 400;
      &:before {
        display: block;
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        box-shadow: 0 0 6px rgba(224, 32, 43, 0.42);
        border-radius: 2px;
        background-color: #e0202b;
      }
    }
  }
  &__button {
    display: block;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 15px 12px;
    border: 0;
    border-radius: 12px;
    background-image: linear-gradient(to top, #e0202b 0%, #f2414c 100%);
    color: #ffffff;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    transform-style: preserve-3d;
    pointer-events: all;
    & > * {
      position: relative;
      z-index: 2;
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 4px;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateZ(-1px);
      box-shadow: 1px 1px 16px rgba(224, 32, 43, 0.42);
      border-radius: 12px;
      background-color: #970c14;
      pointer-events: none;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      &:after {
        background-color: #ca373f;
      }
      background-image: linear-gradient(to top, #ff676f 0%, #ff646e 100%);
      color: #ffffff;
      text-decoration: none;
    }
  }
  &__form {
    display: block;
    position: relative;
    width: 100%;
    max-width: 570px;
    margin: 0 auto;
    padding-top: 35px;
    input {
      display: block;
      position: relative;
      width: 100%;
      margin: 0 0 10px 0;
      padding: 16px 20px;
      border-radius: 12px;
      border: 1px solid #b9b9b9;
      outline: none;
      background-color: #ededed;
      color: #191919;
      font-size: 16px;
      font-weight: 500;
      transition: all 0.2s ease-out;
      &:focus {
        border-color: #df212b;
      }
    }
  }
  &__info {
    display: block;
    position: relative;
    width: 100%;
    margin: 20px 0 0 0;
    color: #191919;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    a {
      color: #191919;
      cursor: pointer;
      text-decoration: underline;
      transition: all 0.2s ease-out;
      &:hover {
        color: #df212b;
        text-decoration: none;
      }
    }
  }
}

.p-map {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 50px 0 150px 0;
  background: url("../images/map_bg.png") no-repeat center top;
  &__title {
    display: block;
    margin: 0 0 60px 0;
    color: #191919;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
  }
  &__list {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0 0 12px 40px;
    list-style: none;
    &:before {
      display: block;
      position: absolute;
      width: 40px;
      left: 0;
      top: -4px;
      content: attr(data-letter);
      text-shadow: 0 0 10px rgba(224, 32, 43, 0.34);
      color: #df212b;
      font-size: 25px;
      font-weight: 700;
      text-transform: uppercase;
    }
    & > li {
      display: inline-block;
      position: relative;
      & > a {
        display: inline-block;
        position: relative;
        margin: 0 0 10px 0;
        border-bottom: 1px dashed #a6a6a6;
        color: #191919;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.1;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;
        &:hover,
        &:focus {
          border-color: #df212b;
          color: #df212b;
          text-decoration: none;
        }
      }
    }
  }
  &-modal {
    display: none;
    position: absolute;
    top: -30px;
    left: 100%;
    width: 290px;
    padding: 26px 30px 30px 30px;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background-color: #ffffff;
    font-family: "Montserrat", sans-serif;
    z-index: 8;
    &__close {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      padding: 20px;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
    &__city {
      display: block;
      margin: 0 0 8px 0;
      color: #191919;
      font-size: 24px;
      font-weight: 700;
    }
    &__phone {
      display: block;
      margin: 0 0 4px 0;
      color: #191919;
      font-size: 20px;
      font-weight: 400;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        color: #df212b;
        text-decoration: none;
      }
    }
    &__info {
      display: block;
      margin: 0 0 16px 0;
      opacity: 0.5;
      color: #212121;
      font-size: 14px;
      font-weight: 400;
    }
    &__text {
      display: block;
      margin: 17px 0 0 0;
      color: #191919;
      font-size: 16px;
      font-weight: 400;
    }
    &__button {
      display: inline-block;
      position: relative;
      margin: 0;
      padding: 11px 25px;
      border-radius: 10px;
      background-color: #e0202b;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      & > * {
        position: relative;
        z-index: 2;
      }
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        &:after {
          background-color: #ca373f;
        }
        background-image: linear-gradient(to top, #ff676f 0%, #ff646e 100%);
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
}

.p-now {
  display: block;
  position: relative;
  width: 100%;
  &__title {
    display: block;
    margin: 0;
    color: #191919;
    font-size: 40px;
    font-weight: 900;
    b,
    strong {
      color: #df212b;
      font-weight: 900;
    }
  }
  &-card {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 100%;
    max-width: 470px;
    margin: 40px 0 0 0;
    &__number {
      display: block;
      flex: 0 0 35px;
      color: #c2c2c2;
      font-size: 18px;
      font-weight: 700;
    }
    &__image {
      display: block;
      flex: 0 0 90px;
      height: 50px;
      object-fit: scale-down;
      object-position: 0 0;
    }
    &__text {
      display: block;
      margin: 0;
      color: #191919;
      font-size: 17px;
      font-weight: 700;
      line-height: 1.67;
    }
  }
  &-form {
    display: block;
    position: relative;
    width: 100%;
    padding: 36px 40px;
    box-shadow: 5px 6px 32px rgba(62, 71, 40, 0.67);
    border-radius: 30px;
    overflow: hidden;
    background: url("../images/now_bg.jpg") no-repeat center center;
    background-size: cover;
    &__title {
      display: block;
      margin: 0 0 16px 0;
      text-shadow: 0 7px 18px rgba(0, 0, 0, 0.95);
      color: #ffffff;
      font-size: 30px;
      font-weight: 900;
    }
    &__text {
      display: block;
      margin: 0 0 40px 0;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
    }
    &__button {
      display: block;
      position: relative;
      width: 100%;
      margin: 10px 0 0 0;
      padding: 14px 6px;
      border: 0;
      border-radius: 12px;
      background-image: linear-gradient(to top, #e0202b 0%, #f2414c 100%);
      color: #ffffff;
      font-size: 16px;
      font-weight: 900;
      text-align: center;
      transform-style: preserve-3d;
      pointer-events: all;
      & > * {
        position: relative;
        z-index: 2;
      }
      &:after {
        display: block;
        position: absolute;
        content: "";
        top: 4px;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateZ(-1px);
        box-shadow: 1px 1px 16px rgba(224, 32, 43, 0.42);
        border-radius: 12px;
        background-color: #970c14;
        pointer-events: none;
        transition: all 0.2s ease-out;
      }
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        &:after {
          background-color: #ca373f;
        }
        background-image: linear-gradient(to top, #ff676f 0%, #ff646e 100%);
        color: #ffffff;
        text-decoration: none;
      }
    }
    &__file {
      display: block;
      position: relative;
      margin: 10px 0 0 0;
      input {
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
      }
      img,
      svg {
        display: inline-block;
        max-width: 21px;
        max-height: 21px;
        margin-right: 20px;
      }
      & > div {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        max-width: 235px;
        padding: 10px 20px 10px 10px;
        border-radius: 10px;
        background: linear-gradient(to top, #71823f 0%, #a0b864 100%);
        transform-style: preserve-3d;
        color: #ffffff;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        & > * {
          position: relative;
          z-index: 2;
        }
        &:after {
          display: block;
          position: absolute;
          content: "";
          top: 4px;
          left: 0;
          width: 100%;
          height: 100%;
          transform: translateZ(-1px);
          box-shadow: 1px 2px 13px rgba(47, 54, 29, 0.99);
          border-radius: 12px;
          background-color: #3f4c20;
          pointer-events: none;
          transition: all 0.2s ease-out;
        }
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;
        &:hover {
          &:after {
            background-color: #6a7a44;
          }
          background: linear-gradient(to top, #9aad5f 0%, #c0d687 100%);
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
    &__input {
      display: block;
      position: relative;
      width: 100%;
      margin: 0 0 10px 0;
      padding: 16px 20px;
      border-radius: 12px;
      border: 1px solid #b9b9b9;
      outline: none;
      background-color: #ededed;
      color: #191919;
      font-size: 16px;
      font-weight: 500;
      transition: all 0.2s ease-out;
      &:focus {
        border-color: #df212b;
      }
    }
    &__info {
      display: block;
      margin: 25px 0 0 0;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }
}

.p-chance {
  display: block;
  width: 100%;
  overflow: hidden;
  padding: 90px 0 180px 0;
  background: url("../images/chance_bg.jpg") no-repeat center top;
  background-size: cover;
  &_mob {
    display: none;
  }
  &__title {
    display: block;
    margin: 0 0 90px 0;
    color: #191919;
    font-weight: 900;
    font-size: 30px;
    text-align: center;
    &_big {
      font-size: 40px;
    }
    b,
    strong {
      color: #e0202b;
      font-weight: 900;
    }
  }
  &__left {
    display: block;
    position: relative;
    width: 100%;
    max-width: 230px;
    margin: 0 auto;
    padding: 17px 6px;
    border: 0;
    border-radius: 12px;
    background-image: linear-gradient(to top, #e0202b 0%, #f2414c 100%);
    color: #ffffff;
    font-size: 19px;
    font-weight: 900;
    text-align: center;
    transform-style: preserve-3d;
    pointer-events: all;
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 4px;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateZ(-1px);
      box-shadow: 1px 1px 16px rgba(224, 32, 43, 0.42);
      border-radius: 12px;
      background-color: #970c14;
      pointer-events: none;
      transition: all 0.2s ease-out;
    }
    &:before {
      display: block;
      position: absolute;
      content: "";
      bottom: 25px;
      left: -60px;
      width: 54px;
      height: 119px;
      background: url("../images/chance_1.png") no-repeat center center;
      background-size: contain;
      pointer-events: none;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      &:after {
        background-color: #ca373f;
      }
      background-image: linear-gradient(to top, #ff676f 0%, #ff646e 100%);
      color: #ffffff;
      text-decoration: none;
    }
  }
  &__right {
    display: block;
    position: relative;
    width: 100%;
    max-width: 230px;
    margin: 0 auto;
    padding: 17px 6px;
    border: 0;
    border-radius: 12px;
    background-image: linear-gradient(to top, #7f7f7f 0%, #aaaaaa 100%);
    color: #ffffff;
    font-size: 19px;
    font-weight: 900;
    text-align: center;
    transform-style: preserve-3d;
    pointer-events: none;
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 4px;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateZ(-1px);
      box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.42);
      border-radius: 12px;
      background-color: #585858;
      pointer-events: none;
      transition: all 0.2s ease-out;
    }
    &:before {
      display: block;
      position: absolute;
      content: "";
      bottom: 25px;
      right: -60px;
      width: 54px;
      height: 119px;
      background: url("../images/chance_2.png") no-repeat center center;
      background-size: contain;
      pointer-events: none;
    }
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 470px;
    min-height: 440px;
    margin: 0 auto 20px auto;
    padding: 26px 30px 22px 30px;
    box-shadow: 0 7px 43px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    background-color: #ffffff;
    &__title {
      display: block;
      margin: 0 0 38px 0;
      color: #3eba2e;
      font-size: 30px;
      font-weight: 900;
      &_bad {
        color: #e0202b;
      }
    }
    &__ul {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: block;
        position: relative;
        margin: 24px 0 0 0;
        padding: 0 0 0 38px;
        background: url("../images/icon_ok.png") no-repeat 0 4px;
        color: #191919;
        font-size: 19px;
        font-weight: 500;
      }
      &_bad {
        li {
          background: url("../images/icon_bad.png") no-repeat 0 4px !important;
        }
      }
    }
  }
}

.p-remember {
  display: block;
  position: relative;
  margin: -100px 0 0 0;
  padding: 35px 300px 35px 80px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background: #3e4728 url("../images/remember_bg.jpg") no-repeat center center;
  background-size: cover;
  &__title {
    display: block;
    margin: 0;
    text-shadow: 0 7px 18px rgba(0, 0, 0, 0.95);
    color: #ffffff;
    font-size: 35px;
    font-weight: 900;
  }
  &__subtitle {
    display: inline-block;
    margin: 0;
    padding: 0 10px;
    background-color: #df212b;
    text-shadow: 0 7px 18px rgba(0, 0, 0, 0.95);
    color: #ffffff;
    line-height: 42px;
    font-size: 30px;
    font-weight: 700;
  }
  &__image {
    display: block;
    position: absolute;
    right: 30px;
    top: -10px;
    pointer-events: none;
    z-index: 2;
  }
  &:before {
    display: block;
    position: absolute;
    content: "";
    top: 35px;
    left: 30px;
    width: 4px;
    height: calc(100% - 70px);
    box-shadow: 0 0 6px rgba(224, 32, 43, 0.42);
    border-radius: 2px;
    background-color: #e0202b;
  }
}

.p-footer {
  display: block;
  position: relative;
  padding: 60px 0 30px 0;
  background: #ffffff;
  &__logo {
    display: block;
    margin: 0;
    img,
    svg {
      display: block;
      max-width: 260px;
      max-height: 60px;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }
  &__info {
    display: block;
    margin: 18px 0 0 0;
    color: #191919;
    font-size: 14px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
  }
  &__phone {
    display: block;
    margin: 0 0 2px 0;
    color: #191919;
    font-size: 22px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    text-align: right;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      color: #e0202b;
      text-decoration: none;
    }
  }
  &__subtitle {
    display: block;
    margin: 0 0 8px 0;
    color: #a7a7a7;
    font-size: 14px;
    font-weight: 500;
  }
  &__text {
    display: block;
    margin: 0 0 24px 0;
    color: #191919;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
  &__link {
    display: block;
    position: relative;
    color: #e0202b;
    font-size: 18px;
    font-weight: 500;
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
    transition: all 0.2s ease-out;
    &:hover {
      color: #e0202b;
      text-decoration: none;
    }
  }
  &__social {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 100%;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 52px;
      height: 50px;
      margin: 15px 0 0 20px;
      padding: 4px;
      border-radius: 12px;
      background-image: linear-gradient(to top, #e0202b 0%, #f2414c 100%);
      transform-style: preserve-3d;
      pointer-events: all;
      img,
      svg {
        display: block;
        max-width: 24px;
        max-height: 24px;
      }
      &:after {
        display: block;
        position: absolute;
        content: "";
        top: 4px;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateZ(-1px);
        box-shadow: 1px 1px 16px rgba(224, 32, 43, 0.42);
        border-radius: 12px;
        background-color: #970c14;
        pointer-events: none;
        transition: all 0.2s ease-out;
      }
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        &:after {
          background-color: #ca373f;
        }
        background-image: linear-gradient(to top, #ff676f 0%, #ff646e 100%);
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
}

.p-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 100;
  &__centered {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.8);
  }
  &__close {
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 20px;
    z-index: 1;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover,
    &:active,
    &:focus {
      opacity: 0.7;
      text-decoration: none;
    }
  }
  &__content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 30px 0;
    padding: 34px 40px;
    box-shadow: 0 8px 43px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    background-color: #ffffff;
  }
  &__title {
    display: block;
    margin: 0 0 10px 0;
    color: #191919;
    font-size: 30px;
    font-weight: 900;
  }
  &__text {
    display: block;
    margin: 0 0 26px 0;
    color: #191919;
    font-size: 16px;
    font-weight: 400;
  }
  input {
    display: block;
    position: relative;
    width: 100%;
    margin: 0 0 10px 0;
    padding: 16px 20px;
    border-radius: 12px;
    border: 1px solid #b9b9b9;
    outline: none;
    background-color: #ededed;
    color: #191919;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.2s ease-out;
    &:focus {
      border-color: #df212b;
    }
  }
  &__button {
    display: block;
    position: relative;
    width: 100%;
    margin: 20px 0 0 0;
    padding: 14px 6px;
    border: 0;
    border-radius: 12px;
    background-image: linear-gradient(to top, #e0202b 0%, #f2414c 100%);
    color: #ffffff;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    transform-style: preserve-3d;
    pointer-events: all;
    & > * {
      position: relative;
      z-index: 2;
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 4px;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateZ(-1px);
      box-shadow: 1px 1px 16px rgba(224, 32, 43, 0.42);
      border-radius: 12px;
      background-color: #970c14;
      pointer-events: none;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      &:after {
        background-color: #ca373f;
      }
      background-image: linear-gradient(to top, #ff676f 0%, #ff646e 100%);
      color: #ffffff;
      text-decoration: none;
    }
  }
  &__info {
    display: block;
    margin: 20px 0 0 0;
    color: #191919;
    font-size: 13px;
    font-weight: 400;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
  .p-prices__cards {
    position: relative;
    width: 100%;
    overflow: visible;
    .swiper-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: stretch;
      position: relative;
      width: 100%;
      overflow: visible;
    }
    .swiper-slide {
      width: auto;
      height: auto;
      margin: 0 0 30px 0;
    }
  }
  .p-prices-card {
    height: 100%;
    margin: 0 auto 30px auto;
    &_full {
      max-width: 570px;
    }
  }
}

@media (max-width: 1199px) {
  body {
    background: #ffffff;
  }
  .p-header {
    padding: 7px 0;
  }
  .p-header > .container {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }
  .p-header__logo {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    img,
    svg {
      max-width: 180px;
    }
  }
  .p-header__logo-text {
    margin: 4px 0 0 0;
    color: #191919;
    font-size: 10px;
    font-weight: 500;
    br {
      display: none;
    }
  }
  .p-header__right {
    align-items: center;
    position: relative;
    width: 100%;
    padding: 15px 0 0 0;
    /* background: url("../images/mob_header_bg.png") no-repeat 0 3px; */
  }
  .p-header__button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 10px;
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    border-radius: 12px;
    span {
      display: none;
    }
    img,
    svg {
      display: block;
    }
  }
  .p-header__text {
    margin: 4px 0 0 0;
    font-size: 12px;
    font-weight: 500;
    br {
      display: none;
    }
  }
  .p-header__city {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 12px 0 0 0;
  }
  .p-top {
    background: url("../images/mob_top_bg.jpg") no-repeat center top;
    text-align: center;
  }
  .p-top__title {
    display: block;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 10px;
    padding: 12px;
    box-shadow: 0 7px 21px rgba(0, 0, 0, 0.24);
    border-radius: 30px;
    background-color: #ffffff;
    font-size: 28px;
  }
  .p-top__text {
    margin: 10px 0 0 0;
    font-size: 16px;
  }
  .p-top__subtext {
    margin-bottom: 8px;
    font-size: 14px;
  }
  .p-top__button {
    width: 100%;
    padding: 14px;
    font-size: 18px;
  }
  .p-top__info {
    margin: 10px 0 0 0;
    font-size: 12px;
    &:before {
      left: -15px;
      top: -20px;
      width: 27px;
      height: 54px;
      background: url("../images/mob_top_before.png") no-repeat center center;
    }
  }
  .p-features {
    padding: 0;
  }
  .p-features-card__image {
    height: auto;
    img,
    svg {
      max-width: 220px;
      max-height: 170px;
    }
  }
  .p-features-card_mob {
    max-width: 150px !important;
  }
  .p-features-card__title {
    margin: 18px 0 12px 0;
    font-size: 20px;
  }
  .p-features-card__text {
    font-size: 14px;
  }
  .p-how {
    padding-top: 60px;
    background: url("../images/mob_how_bg.png") no-repeat center bottom -40px;
    text-align: center;
  }
  .p-how__title {
    width: 100%;
    position: relative;
    margin-bottom: 26px;
    font-size: 25px;
    &:before {
      display: block;
      position: absolute;
      content: "";
      top: -20px;
      left: 50%;
      width: 100px;
      height: 4px;
      margin-left: -50px;
      box-shadow: 0 0 6px rgba(224, 32, 43, 0.42);
      border-radius: 2px;
      background-color: #e0202b;
    }
    br {
      display: none;
    }
  }
  .p-how__text {
    margin-bottom: 22px;
    font-size: 16px;
    br {
      display: none;
    }
  }
  .p-how__subtext {
    margin-bottom: 240px;
    font-size: 18px;
  }
  .p-how__centered {
    max-width: 280px;
    padding: 8px;
    border-radius: 12px 12px 0 0;
    font-size: 14px;
  }
  .p-stroke {
    min-height: 50px;
    font-size: 20px;
    line-height: 50px;
  }
  .p-real {
    padding: 60px 0 170px 0;
    background: url("../images/mob_real_bg.jpg") no-repeat center center;
    background-size: cover;
  }
  .p-real__title {
    margin-bottom: 22px;
    font-size: 25px;
  }
  .p-real-card {
    min-height: auto;
    margin-bottom: 20px;
    padding: 26px 20px;
    &:after {
      display: block;
      position: absolute;
      content: "";
      width: 316px;
      height: 364px;
      top: 5px;
      right: -15px;
      background: url("../images/mob_real_2.png") no-repeat center center;
      background-size: contain;
      pointer-events: none;
      z-index: 3;
    }
    &_another {
      &:after {
        display: block;
        position: absolute;
        content: "";
        width: 133px;
        height: 163px;
        top: 110px;
        right: -34px;
        background: url("../images/mob_real_1.png") no-repeat center center;
        background-size: contain;
        pointer-events: none;
        z-index: 3;
      }
    }
  }
  .p-real-card__title {
    margin-bottom: 24px;
    font-size: 20px;
    text-align: center;
  }
  .p-real-card__ul li {
    margin-bottom: 25px;
    font-size: 16px;
  }
  .p-real__left {
    display: none;
  }
  .p-real__right {
    display: none;
  }
  .p-real__good {
    display: none;
  }
  .p-real__bad {
    display: none;
  }
  .p-yes {
    margin-top: -200px;
    margin-bottom: 40px;
    padding: 150px 0 0 0;
    background: url("../images/mob_yes_bg.png") no-repeat center top;
    background-size: 100% auto;
    text-align: center;
  }
  .p-yes__title {
    margin-bottom: 25px;
    font-size: 25px;
  }
  .p-yes__text {
    margin-bottom: 20px;
    font-size: 16px;
  }
  .p-yes__mob {
    display: block;
    max-width: 100%;
    margin: 20px auto 0 auto;
  }
  .p-features-card__image_mob_2 {
    max-width: 157px !important;
  }
  .p-law {
    padding: 60px 0 20px 0;
    background: url("../images/mob_law_alt_bg.jpg") no-repeat center top;
    background-size: 100% auto;
    &__image {
      display: block;
      position: relative;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  .p-law__title {
    width: 100%;
    position: relative;
    margin-bottom: 22px;
    font-size: 25px;
    text-align: center;
    &:before {
      display: block;
      position: absolute;
      content: "";
      top: -20px;
      left: 50%;
      width: 100px;
      height: 4px;
      margin-left: -50px;
      box-shadow: 0 0 6px rgba(224, 32, 43, 0.42);
      border-radius: 2px;
      background-color: #e0202b;
    }
    br {
      display: none;
    }
  }
  .p-law__text {
    margin-top: -90px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1;
    text-align: center;
  }
  .p-law__text b,
  .p-law__text strong {
    font-size: 30px;
  }
  .p-law-card__title {
    font-size: 16px;
  }
  .p-law-card__text {
    font-size: 16px;
  }
  .p-law__button {
    width: 100%;
    padding: 12px;
    font-size: 18px;
  }
  .p-law-block {
    max-width: 280px;
    margin: 80px auto 0 auto;
    padding: 290px 30px 20px 30px;
    background: url("../images/mob_law_bg_another.jpg") no-repeat center top;
    background-size: cover;
    text-align: center;
  }
  .p-law-block__title {
    margin-bottom: 20px;
    font-size: 20px;
    br {
      display: none;
    }
  }
  .p-law-block__text {
    font-size: 16px;
    line-height: 1.5;
    br {
      display: none;
    }
  }
  .p-steps {
    padding: 40px 0 0 0;
    background: url("../images/mob_steps_bg.png") no-repeat center bottom 190px;
  }
  .p-steps__title {
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    font-size: 25px;
    text-align: center;
    &:before {
      display: block;
      position: absolute;
      content: "";
      top: -20px;
      left: 50%;
      width: 100px;
      height: 4px;
      margin-left: -50px;
      box-shadow: 0 0 6px rgba(224, 32, 43, 0.42);
      border-radius: 2px;
      background-color: #e0202b;
    }
    br {
      display: none;
    }
  }
  .p-steps__text {
    margin-bottom: 40px;
    font-size: 20px;
  }
  .p-steps-card:before {
    display: block;
    position: absolute;
    content: "";
    bottom: -45px;
    left: 50%;
    width: 18px;
    height: 24px;
    margin-left: -9px;
    background: url("../images/mob_arrow_down.png") no-repeat center center;
    background-size: contain;
  }
  .p-steps-card:after {
    display: none;
  }
  .p-steps-card__image {
    height: auto;
    img,
    svg {
      max-width: 164px;
      max-height: 164px;
    }
  }
  .p-steps-card__title {
    margin-top: 18px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
  }
  .p-steps blockquote {
    margin: 20px auto 50px auto;
    padding: 42px 20px 16px 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    &:before {
      top: 20px;
      left: 20px;
      width: calc(100% - 40px);
      height: 4px;
      box-shadow: 0 0 6px rgba(224, 32, 43, 0.42);
      border-radius: 2px;
      background-color: #e0202b;
    }
  }
  .p-working {
    overflow: visible;
    padding: 320px 0 20px 0;
    background: url("../images/mob_working_bg.jpg") no-repeat left top;
    text-align: center;
  }
  .p-working__title {
    margin-bottom: 20px;
    font-size: 25px;
    br {
      display: none;
    }
  }
  .p-working__text {
    margin-bottom: 20px;
    font-size: 20px;
    br {
      display: none;
    }
  }
  .p-working__button {
    width: 100%;
    padding: 14px;
    font-size: 18px;
  }
  .p-formula__title {
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    font-size: 25px;
    text-align: center;
    &:before {
      display: block;
      position: absolute;
      content: "";
      top: -20px;
      left: 50%;
      width: 100px;
      height: 4px;
      margin-left: -50px;
      box-shadow: 0 0 6px rgba(224, 32, 43, 0.42);
      border-radius: 2px;
      background-color: #e0202b;
    }
    br {
      display: none;
    }
  }
  .p-formula__text {
    font-size: 16px;
    line-height: 1.5;
  }
  .p-formula {
    padding: 80px 0 0 0;
    background: url("../images/mob_formula_bg.jpg") no-repeat center bottom 380px;
    background-size: auto auto;
    text-align: center;
  }
  .p-formula__block {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 220px auto 0 auto;
    padding: 35px 5px;
  }
  .p-way {
    position: relative;
    margin-top: -160px;
    padding-top: 200px;
    padding-bottom: 90px;
    background: url("../images/mob_way_bg.jpg") no-repeat center top;
    background-size: 100% auto;
    &:after {
      display: block;
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 434px;
      background: url("../images/mob_way_bg_after.png") no-repeat center bottom;
      z-index: 0;
    }
  }
  .p-way__title {
    margin-bottom: 26px;
    font-size: 25px;
    span {
      display: inline;
    }
  }
  .p-way-card {
    min-height: auto;
    padding: 16px 12px 6px 12px;
    z-index: 2;
  }
  .p-way-card__title {
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
  }
  .p-way-card__ul li {
    margin-bottom: 28px;
    font-size: 14px;
    br {
      display: none;
    }
  }
  .p-way__title_alt {
    display: none;
  }
  .p-way__1 {
    display: none;
  }
  .p-way__2 {
    display: none;
  }
  .p-way__3 {
    display: none;
  }
  .p-way__4 {
    display: none;
  }
  .p-prices {
    padding-top: 0;
  }
  .p-prices__title {
    margin-bottom: 8px;
    font-size: 25px;
  }
  .p-prices__cards {
    overflow: visible;
    .swiper-pagination {
      display: block;
      bottom: -10px;
    }
    .swiper-pagination-bullet {
      width: 20px;
      height: 20px;
      margin: 0 10px;
      opacity: 1;
      background: #e0202b;
    }
  }
  .p-credit {
    padding: 28px 10px 40px 10px;
    text-align: center;
  }
  .p-credit__title {
    font-size: 20px;
    b,
    strong {
      font-size: 25px;
    }
  }
  .p-credit__text {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }
  .p-credit__button {
    padding: 8px;
    font-size: 18px;
  }
  .p-reviews__title {
    margin-bottom: 90px;
    font-size: 25px;
  }
  .p-reviews__cards .swiper-slide-active .p-reviews-card {
    min-height: 620px;
  }
  .p-reviews .swiper-button-prev {
    left: 100px;
  }
  .p-reviews .swiper-button-next {
    right: 100px;
  }
  .p-done__title {
    margin-bottom: 15px;
    font-size: 25px;
  }
  .p-team__title {
    margin-bottom: 70px;
    font-size: 20px;
  }
  .p-team__title_big {
    font-size: 25px;
  }
  .p-team__cards .swiper-button-prev {
    margin-left: -15px;
    opacity: 0.3;
  }
  .p-team__cards .swiper-button-next {
    margin-right: -15px;
    opacity: 0.3;
  }
  .p-team-card {
    padding: 180px 20px 20px 20px;
    position: relative;
    .col-xl-6,
    .row {
      position: static;
    }
  }
  .p-team-card__image {
    position: absolute;
    justify-content: center;
    width: 100%;
    top: 0;
  }
  .p-team-card__name {
    margin-bottom: 16px;
    font-size: 20px;
  }
  .p-team-card__who {
    margin-bottom: 13px;
    font-size: 16px;
  }
  .p-team-card blockquote {
    padding: 14px 0 14px 14px;
    font-size: 12px;
  }
  .p-team {
    padding-bottom: 20px;
  }
  .p-done__info {
    margin: 32px 0 0 0;
    font-size: 20px;
    br {
      display: none;
    }
  }
  .p-map__title {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    font-size: 25px;
    text-align: center;
    &:before {
      display: block;
      position: absolute;
      content: "";
      top: -20px;
      left: 50%;
      width: 100px;
      height: 4px;
      margin-left: -50px;
      box-shadow: 0 0 6px rgba(224, 32, 43, 0.42);
      border-radius: 2px;
      background-color: #e0202b;
    }
  }
  .p-map__scroll {
    display: block;
    width: calc(100% - 15px);
    height: 540px;
    overflow-y: auto;
    margin-left: 15px;
    padding: 26px 15px;
    border-radius: 10px;
    border: 1px solid #d6d6d6;
    background-color: #f9f9f9;
  }
  .p-map__list {
    & > li {
      position: static;
    }
  }
  .p-map-modal {
    left: 0;
    padding: 30px 20px;
  }
  .p-map {
    padding-bottom: 45px;
    background: #ffffff;
  }
  .p-now-form {
    padding: 26px 10px;
    text-align: center;
  }
  .p-now-form__title {
    font-size: 20px;
  }
  .p-now-form__text {
    margin-bottom: 15px;
    font-size: 16px;
  }
  .p-now-form__file > div {
    max-width: 100%;
  }
  .p-now-form__file {
    margin: 0;
  }
  .p-now-form__button {
    margin: 5px 0 0 0;
  }
  .p-now-form__info {
    margin: 10px 0 0 0;
    font-size: 12px;
  }
  .p-now__title {
    margin: 50px 0 0 0;
    font-size: 25px;
    text-align: center;
  }
  .p-now-card {
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 30px;
    text-align: center;
    &__text {
      flex: 1 1 100%;
      margin: 6px 0 0 0;
      font-size: 18px;
      line-height: 1.2;
    }
  }
  .p-chance {
    background: url("../images/mob_chance_bg.jpg") no-repeat center top;
    background-size: 100% auto;
  }
  .p-chance__title {
    position: relative;
    margin-bottom: 0;
    font-size: 20px;
    text-align: center;
    &:before {
      display: block;
      position: absolute;
      content: "";
      top: -20px;
      left: 50%;
      width: 100px;
      height: 4px;
      margin-left: -50px;
      box-shadow: 0 0 6px rgba(224, 32, 43, 0.42);
      border-radius: 2px;
      background-color: #e0202b;
    }
    br {
      display: none;
    }
  }
  .p-chance__title_big {
    font-size: 25px;
  }
  .p-chance-card {
    min-height: auto;
    position: relative;
    margin-bottom: 20px;
    margin-top: 220px;
    padding: 26px 20px;
    transform-style: preserve-3d;
    &:after {
      display: block;
      position: absolute;
      content: "";
      width: 519px;
      height: 380px;
      top: -210px;
      left: -80px;
      background: url("../images/mob_chance_1.png") no-repeat center center;
      background-size: contain;
      pointer-events: none;
      transform: translateZ(-1px);
    }
    &_another {
      position: relative;
      margin-top: 150px;
      &:after {
        display: block;
        position: absolute;
        content: "";
        width: 212px;
        height: 304px;
        top: -140px;
        left: 30px;
        background: url("../images/mob_chance_2.png") no-repeat center center;
        background-size: contain;
        pointer-events: none;
        transform: translateZ(-1px);
      }
    }
  }
  .p-chance-card__title {
    margin-bottom: 24px;
    font-size: 20px;
    text-align: center;
  }
  .p-chance-card__ul li {
    margin-bottom: 25px;
    font-size: 16px;
  }
  .p-chance__left {
    display: none;
  }
  .p-chance__right {
    display: none;
  }
  .p-remember {
    padding: 50px 30px 260px 30px;
    text-align: center;
    &:before {
      top: 24px;
      left: 20px;
      width: calc(100% - 40px);
      height: 4px;
      box-shadow: 0 0 6px rgba(224, 32, 43, 0.42);
      border-radius: 2px;
      background-color: #e0202b;
    }
  }
  .p-remember__image {
    top: auto;
    bottom: 14px;
    left: 44px;
    right: 44px;
  }
  .p-remember__subtitle {
    display: block;
    max-width: 160px;
    margin: 0 auto 22px auto;
  }
  .p-remember__title {
    font-size: 25px;
  }
  .p-footer {
    text-align: center;
  }
  .p-footer__logo img,
  .p-footer__logo svg {
    margin: 0 auto;
  }
  .p-footer__subtitle {
    margin-top: 30px;
  }
  .p-footer__phone {
    text-align: center;
  }
  .p-footer__link {
    text-align: center;
  }
  .p-footer__social {
    justify-content: center;
    padding-top: 30px;
    a {
      margin: 10px 10px 0 10px;
    }
  }
  .p-modal__content {
    padding: 50px 10px;
    text-align: center;
  }
  .p-modal__title {
    width: 100%;
    font-size: 20px;
  }
  .p-modal__text {
    font-size: 16px;
  }
  .p-prices__cards {
    width: 100%;
  }
  .p-steps__bilet {
    position: relative;
    bottom: auto;
    right: auto;
    max-width: 100%;
    margin: -20px auto 0 auto;
    &:after {
      width: 497px;
      height: 497px;
      margin-left: -248px;
      margin-top: -248px;
    }
    &:before {
      width: 385px;
      height: 385px;
      margin-left: -192px;
      margin-top: -192px;
    }
  }
  .p-steps {
    overflow: hidden;
    background: transparent;
  }
  .p-law {
    overflow: visible;
  }
  .p-prices__cards {
    .swiper-wrapper {
      display: flex;
      align-items: stretch;
    }
  }
  .p-prices-card {
    ul {
      flex: 1 1 auto;
      min-height: 479px;
    }
  }
  .p-credit {
    background-position: right center;
  }
  .p-team__cards {
    padding-bottom: 60px;
    .swiper-button-next,
    .swiper-button-prev {
      &:before,
      &:after {
        display: none;
      }
      width: 50px;
      height: 24px;
      top: auto;
      bottom: 0;
      background-size: contain;
      outline: none;
      opacity: 1;
      filter: brightness(0);
      z-index: 10;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        filter: none;
        text-decoration: none;
      }
    }
    .swiper-button-prev {
      left: 100px;
      background: url("../images/reviews_prev.png") no-repeat center center;
    }
    .swiper-button-next {
      right: 100px;
      background: url("../images/reviews_next.png") no-repeat center center;
    }
  }
  .p-team-card__image img,
  .p-team-card__image svg {
    width: 200px;
    height: 200px;
    left: 50%;
    margin-left: -100px;
    object-fit: contain;
    background-position: center bottom;
  }
  .p-team-card__image {
    left: 0;
    top: -20px;
  }
  .p-now-form {
    margin-left: auto;
    margin-right: auto;
    padding: 25px 10px 20px 10px;
  }
  .p-now-form__file {
    width: 100%;
    margin: 0 auto 5px auto;
  }
  .p-now-form__title {
    font-size: 19px;
    br {
      display: none;
    }
  }
  .p-remember {
    background: url("../images/mob_remember_bg.jpg") no-repeat center center;
    background-size: cover;
  }
  .p-top {
    margin-top: -50px;
  }
  .p-now-form__file > div {
    min-height: 47px;
    br {
      display: none;
    }
  }
  .p-way-card_1 {
    position: relative;
    &:before {
      display: block;
      content: "";
      position: absolute;
      top: -25px;
      right: -30px;
      width: 92px;
      height: 140px;
      background: url("../images/mob_way_1.png") no-repeat center center;
      background-size: contain;
      pointer-events: none;
      z-index: 4;
    }
    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 410px;
      left: -15px;
      width: 100%;
      height: 500px;
      background: url("../images/mob_way_2.png") no-repeat left top;
      background-size: contain;
      pointer-events: none;
      z-index: 4;
    }
  }
  .p-way-card_2 {
    position: relative;
    &:before {
      display: block;
      content: "";
      position: absolute;
      top: -20px;
      left: -40px;
      width: 110px;
      height: 106px;
      background: url("../images/mob_way_3.png") no-repeat center center;
      background-size: contain;
      pointer-events: none;
      z-index: 4;
    }
    &:after {
      display: block;
      content: "";
      position: absolute;
      bottom: -120px;
      right: -5px;
      width: 141px;
      height: 156px;
      background: url("../images/mob_way_4.png") no-repeat left top;
      background-size: contain;
      pointer-events: none;
      z-index: 4;
    }
  }
  .p-chance_mob {
    display: flex;
    padding-top: 15px;
    .p-chance__left {
      display: block;
      width: 100%;
      &:before {
        display: none;
      }
    }
    .p-chance__right {
      display: block;
      &:before {
        display: none;
      }
    }
  }
  .p-real-card__ul li {
    padding-bottom: 5px;
  }
}
